import React from "react";
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';


const Alicja = () => {
    return (
        <Layout>
            <Seo title="Alicja Rukowicz"/>
            <section className="member-container">
                <section className="member-intro">
                    <div className="member-intro_text">
                        <h1 className="member-intro_text_name">Alicja Rukowicz</h1>
                        <p className="member-intro_text_description">Właścicielka Studia, dyplomowana instruktorka Pilatesu terapeutycznego
                        </p>
                    </div>
                    <div className="member-intro_picture-container">
                        <StaticImage 
                            src='../images/poza_alicja.jpg' 
                            placeholder="blurred"
                            loading="eager"
                            alt="nstruktorka Alicja Rukowicz" 
                            className='member-intro_picture'
                        />
                    </div>
                </section>
                <section className="member-hero">
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">O ALICJI</h2>
                        </div>
                        <div className="member-hero-paragraph_info">
                            <h3 className="member-hero-paragraph_info-bold">Właścicielka Studia Pilatesu terapeutycznego, dyplomowana instruktorka Pilatesu terapeutycznego, certyfikowany trener wielu metod pracy z ciałem, lingwista, marketingowiec i pedagog.
                            </h3>
                            <br/>
                            <p className="member-hero-paragraph_info-text">Alicja pracuje metodą Pilates w nurcie współczesnym, czyli sportowo-rehabilitacyjnym od 10 lat. Ukończyła wiele kursów metody Pilates w różnych specjalizacjach oraz ponad 50 szkoleń z dziedziny anatomii, biomechaniki, uroginekologii, warsztatów stretchingowych, szkoleń terapeutycznych, oraz wielu innych metod pracy z ciałem (terapie manualne, terapie oddechowe, praca z powięzią wg Anatomy Trains, treningi neurologii funkcjonalnej, itp.).
                            </p>
                            <p className="member-hero-paragraph_info-text">Reprezentuje podejście Pilatesu terapeutycznego, z nastawieniem na pracę indywidualną, na macie, w przestrzeni, z małym sprzętem i na maszynach w oparciu o rzetelną diagnostykę, dzięki czemu ma możliwość skupienia się na pracy nad konkretnymi potrzebami i może bezpiecznie pracować z osobami z tzw. grup wrażliwych.</p>
                            <p className="member-hero-paragraph_info-text">W metodzie Pilates najbardziej ceni sobie pracę nad świadomością ciała, kontrolą ruchu, przywrócenie równowagi i siły poszczególnych części ciała, a przez to zapobieganie kontuzjom oraz wyprowadzenie z ewentualnych dysfunkcji mięśniowych.</p>
                            <p className="member-hero-paragraph_info-text">Tworzy, organizuje i prowadzi kursy instruktorskie przygotowujące przyszłych trenerów do pracy metodą Pilates w nurcie współczesnym oraz rozbudowujące kompetencje obecnych trenerów w specjalistycznej ścieżce rehabilitacyjnej.</p>
                            <p className="member-hero-paragraph_info-text">Przez kilka lat organizowała kurs „Biomechanika w praktyce - warsztaty dla trenerów różnych metod pracy z ciałem” do którego opracowała program, a którego każda edycja była prowadzona przez fizjoterapeutę wykładowcę i praktyka w jednym. Na podobnej zasadzie stworzyła również program „Neuroimmunologia w praktyce - warsztaty terapii bólu dla trenerów pracy z ciałem”. Obecnie prowadzi autorski projekt edukacyjny w postaci rocznego kursu dla instruktorów „Bio-Logika w Pilatesie - Pilates Teacher Excellence Program”, a także kursy indywidualne na instruktora Pilates w nurcie współczesnym w podejściu rehabilitacyjno-terapeutycznym.</p>
                            <p className="member-hero-paragraph_info-text">Stale rozwija się w różnych formach pracy z ciałem i przywracania naturalnego ruchu.</p>
                            <p className="member-hero-paragraph_info-text">Interesuje się filogenetycznym rozwojem postawy ciała oraz neurologią funkcjonalną i neuroplastycznością w kontekście układu ruchu i terapii bólu.</p>
                            <p className="member-hero-paragraph_info-text">Wcześniej przez wiele lat była Managerem Centrum Szkoleniowego w międzynarodowej organizacji, gdzie organizowała ponad 100 szkoleń i spotkań rocznie. W ramach swojego stanowiska była również współtwórcą kwalifikacji z zakresu budowania relacji z klientem, obsługi klienta i prowadzenia sprzedaży w Polsce w ramach europejskiego projektu tworzenia Zintegrowanego Systemu Kwalifikacji Europejskich Ram Kwalifikacji. Pracowała też z kilkoma Uczelniami wyższymi w Warszawie celu dopasowywania kierunków studiów pod potrzeby pracodawców i uczestniczyła w tworzeniu nowych specjalizacji w ramach roli członka Rady Programowej Uczelni.</p>
                            <p className="member-hero-paragraph_info-text">Swoją karierę zawodową zaczynała w firmach związanych z wizerunkiem marki, zarządzaniem kulturą marki i identyfikacją wizualną. Skończyła m.in. studia podyplomowe na SGH w Warszawie na kierunku „Zarządzenie marką”, ale z wykształcenia jest także lingwistą i pedagogiem.</p>
                            <p className="member-hero-paragraph_info-text">W każdej swej roli dbała i dba o profesjonalne podejście do Klienta, o autentyczność relacji, rzetelność usług i budowanie długotrwałego zaufania swoich Klientów.</p>
                            <p className="member-hero-paragraph_info-text">W swoim Studio prowadzi zajęcia Pilates stacjonarnie oraz on-line. Zajmuje się również prowadzeniem zajęć dla firm i instytucji. Występuje na różnych wydarzeniach w obszarze zdrowej aktywności fizycznej, na Konferencjach i Webinariach.</p>
                        </div>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">JĘZYKI<br/>LANGUAGES</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Polski/ Polish</li>
                            <li>Angielski/ English</li>
                            <li>Francuski/ French</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">METODY</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Pilates</li>
                                <li>Trening neurologii funkcjonalnej</li>
                                <li>Trening mięśni dna miednicy</li>
                                <li>Terapie oddechowe</li>
                                <li>Stretching</li>
                                <li>Trening dłoni i nadgarstków</li>
                                <li>Trening stóp i stawów skokowych</li>
                                <li>Trening oczu</li>
                                <li>Trening równowagi i propriocepcji</li>
                                <li>Automasaż i relaksacja</li>
                                <li>Kreacja ruchu</li>
                                <li>Anatomy Trains</li>
                                <li>Terapia stawu skroniowo-żuchwowego</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE ZAJĘĆ</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Indywidualnie</li>
                                <li>W duetach</li>
                                <li>W minigrupach</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE SPRZĘTU</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Reformer with Tower</li>
                                <li>Ladder Barrel</li>
                                <li>Spine Corrector</li>
                                <li>Mata oraz sprzęt pilatesowy typu : Roller, Disc, Small Ball, Heavy Ball, Ovo Ball, Body Ball, Band, Magic Circle oraz dodatkowe akcesoria sportowe, np. małe ciężarki, piłeczki do automasażu, itp.</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">SPECJALIZACJE</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Osoby z wadami postawy</li>
                                <li>Osoby z dużymi dolegliwościami bólowymi</li>
                                <li>Osoby po wypadkach, urazach, operacjach</li>
                                <li>Kobiety przygotowujące się do zajścia w ciążę</li>
                                <li>Osoby onkologiczne</li>
                                <li>Osoby niewidome</li>
                                <li>Osoby z dysfunkcjami w aparacie ruchu wynikającymi z wad wrodzonych, chorób genetycznych czy autoimmunologicznych lub schorzeń nabytych.</li>
                                <li>Sportowcy (Tenis, Łucznictwo, Wspinaczka, Pływanie)</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title"></div>
                        <h4 className="member-hero-paragraph_info-bold">Zapisy na zajęcia z Alicją tylko mailowo</h4>
                        <div className="member-hero-paragraph_info_link-container">
                            <a href="mailto:alicja.rukowicz@gmail.com" className="member-hero-paragraph_info_link-link">ZAPISZ SIĘ</a>
                        </div>
                    </section>
                </section>
            </section>
        </Layout>
    )
}

export default Alicja;

